<template>
  <div class="flex-box vertical content">
    <div class="data-title">
      <div class="rec-title-text">共{{ pagination.total }}条记录</div>
      <a-form-model class="query-form" layout="inline"> </a-form-model>
      <a-space>
        <a-button @click="getList">查询</a-button>
        <a-button type="primary" @click="addAdmin">添加管理员</a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
        ref="dataTable"
        :columns="columns"
        row-key="id"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{ y: h }"
        @change="handleTableChange"
      >
        <template v-slot:scope="text, record">
          <template v-if="record.books && record.books.length > 0">
            <div class="book-item" v-for="(b, i) in record.books" :key="i">
              <div class="icon-tag book-icon" v-if="b.type == 'tag'"></div>
              <a-icon class="book-icon" :type="b.type == 'user' ? 'user' : 'folder'" v-else />
              <div class="book-name" v-if="b.type == 'tag'">{{ b.name }}</div>
              <open-data class="book-name" :type="`${b.type}Name`" :openid="b.id" v-else />
            </div>
          </template>
        </template>
        <template v-slot:action="text, record">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="editAdmin(record)">编辑</a>
            <a class="txt-btn danger" @click.stop="delAdmin(record)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal :title="form.id ? '编辑' : '添加'" :width="640" :footer="null" v-model="visible">
      <form-area
        ref="adminForm"
        class="flex-box vertical"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :items="items"
        :entity="form"
        confirm-text="确定"
        @cancel="cancel()"
        @confirm="confirm"
      ></form-area>
    </a-modal>
  </div>
</template>

<script>
import {
  getSysList,
  // getSystemRoleName,
  // getSysName,
} from "../../permission/subsystem";
import { clone } from "../../common/js/tool";
import { moduleOptions } from '../../common/constant/safe';

export default {
  name: "SafeAuth",
  data() {
    return {
      type: "safe",
      h: 500,
      list: [],
      form: {},
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      admin: null,
      visible: false,
      columns: [
        { title: "权限范围", dataIndex: "user_id", scopedSlots: { customRender: "scope" } },
        {
          title: "权限",
          dataIndex: "module_config",
          customRender: (text) => {
            let res = "";
            let modules = moduleOptions?.filter((item) => text.indexOf(item.value) > -1);
            if (modules.length == moduleOptions.length) {
              res = "全部权限";
            } else {
              res = modules?.map((item) => item.label)?.join("、");
            }
            return res;
          },
        },
        { title: "操作", key: "action", scopedSlots: { customRender: "action" } },
      ],
      items: [
        {
          key: "books",
          label: "权限范围",
          component: "select-book",
          props: {
            multiple: true,
            type: "book",
          },
          rules: [{ required: true, message: "请设置权限范围", trigger: "change" }],
        },
        {
          key: "module_config",
          label: "权限",
          component: "checkbox-group",
          props: {
            options: [],
            placeholder: "请选择模块",
          },
          style: {
            width: "100%",
          },
          rules: [{ required: true, message: "请选择模块", trigger: "change" }],
        },
      ],
      moduleOptions,
    };
  },
  computed: {
    sysId() {
      let type = this.type;
      let sys = getSysList().find((s) => s.type == type);
      return sys ? sys.id : null;
    },
  },
  created() {
    this.items[1].props.options = this.moduleOptions;
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    confirm(formData) {
      let form = clone(formData);
      let url = "/user-role";
      let method = "POST";
      let type = this.type;
      let books = form.books;
      let user_id = [],
        dept_id = [],
        tag_id = [];
      books.forEach((b) => {
        if (b.type == "user") {
          user_id.push(b.id);
        } else if (b.type == "department") {
          dept_id.push(b.id);
        } else if (b.type == "tag") {
          tag_id.push(b.id);
        }
      });
      form.user_id = JSON.stringify(user_id);
      form.dept_id = JSON.stringify(dept_id);
      form.tag_id = JSON.stringify(tag_id);
      if (form.id) {
        url += `/${form.id}`;
        method = "PATCH";
      } else if (type != "super") {
        form.system = this.sysId;
      }
      if (!form.id) {
        // 默认系统管理员
        form.privilege = 1;
      }
      this.$axios({
        url,
        method,
        data: form,
      }).then(() => {
        this.$message.success(form.id ? "更新成功" : "添加成功");
        this.getList();
        this.visible = false;
      });
    },
    editAdmin(record) {
      let form = clone(record);
      this.form = form;
      this.visible = true;
    },
    delAdmin(record) {
      this.$confirm({
        title: "提示",
        content: "确定删除此权限吗？",
        onOk: () => {
          let url = `/admin/user-role/${record.id}`;
          this.$axios({
            url,
            method: "DELETE",
          }).then(() => {
            this.$message.success("已删除");
            this.getList();
          });
        },
      });
    },
    addAdmin() {
      this.form = {};
      this.visible = true;
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    getList() {
      this.loading = true;
      let url = `/admin/user-role?sort=-id`;
      let { current, pageSize } = this.pagination;
      url += `&page=${current}&pageSize=${pageSize}`;
      if (this.sysId) {
        url += `&filter[system]=${this.sysId}`;
      }
      let privilege = this.privilege;
      if (privilege && privilege.length > 0) {
        if (privilege.length == 1) {
          url += `&filter[privilege]=${privilege[0]}`;
        } else {
          privilege.forEach((p, i) => {
            url += `&filter[or][${i}][privilege]=${p}`;
          });
        }
      }
      this.$axios({ url })
        .then((res) => {
          this.loading = false;
          let list = res.data;
          list.forEach((item) => {
            this.dealAdmin(item);
          });
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dealAdmin(item) {
      let books = [];
      if (item.user_id) {
        let user = JSON.parse(item.user_id);
        books.push(
          ...user.map((u) => {
            return {
              type: "user",
              id: u,
            };
          })
        );
      }
      if (item.dept_id) {
        let dept = JSON.parse(item.dept_id);
        books.push(
          ...dept.map((d) => {
            return {
              type: "department",
              id: d,
            };
          })
        );
      }
      if (item.tag) {
        for (let key in item.tag) {
          books.push({
            type: "tag",
            id: key,
            name: item.tag[key],
          });
        }
      }
      item.books = books;
      // item.module_config = item.module_config?.split(",");
      // item.roleText = getSystemRoleName(item.system, item.privilege);
      // item.systemName = getSysName(item.system);
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
  },
};
</script>

<style scoped lang="less">
.system-admin {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
